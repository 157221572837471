module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Droid Sans","Droid Serif","Montserrat","Fondamento","Merriweather","Cinzel"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Biuro projektowe Tech-Drog Arkadiusz Pydzik","short_name":"Tech-Drog","description":"Biuro Projektowe Tech-Drog Arkadiusz Pydzik. Projekty budowlane oraz projekty organizacji ruchu na terenie województw mazowieckiego oraz łódzkiego.","lang":"pl","icon":"src/assets/svg/logo-32x32 SVG.svg","start_url":"/","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"471bbbf1b7ec2ea8bd651fd78cb9d34c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
